import React from 'react';

import { CCard, CCardBody, CCol, CCardHeader, CRow, CCardImage, CImage } from '@coreui/react'
const CancelPage = () => {
  return (
    <div className={'p-2 m-2 border text-center'}>
      <CRow>
        <CCol>
          <h1>Payment Cancelled</h1>
          <p>You cancelled the payment process. Feel free to try again!</p>

        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <p><a href="/#/">Go back to continue using this site.</a></p>
        </CCol>
      </CRow>
    </div>
  );
};

export default CancelPage;
