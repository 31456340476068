import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import ErrorBoundary from './components/ErrorBoundary'
// import App from './App-FACEBOOKLOGIN'

// notes about running in DEV on server - https://chatgpt.com/c/6766def1-0990-800b-836b-a5c600ea0d1d

createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <Provider store={store}>
      <App />
    </Provider >
  </ErrorBoundary>
)
