import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { CCard, CCardBody, CCol, CCardHeader, CRow, CCardImage, CImage } from '@coreui/react'
const SuccessPage = () => {
  const [sessionData, setSessionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    let value = window?.location?.hash || "";

    let sessionId;

    if (value) {
      sessionId = value.split("=")[1];
    } else {
      sessionId = '';
    }

    if (sessionId) {
      // Fetch session details from the backend
      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/retrieve-session?session_id=${sessionId}`)
        .then((response) => response.json())
        .then((data) => {
          if (data?.error) {
            setError(data.error);
            setLoading(false);
            return;
          }
          setSessionData(data);
          setLoading(false);
        })
        .catch((err) => {
          console.error('Error fetching session data:', err);
          setError('Failed to retrieve session details.');
          setLoading(false);
        });
    } else {
      setError('Session ID not found.');
      setLoading(false);
    }
  }, []);

  if (loading) return (

    <div className={'p-2 m-2 border text-center'}>
      <CCard>
        Loading...
      </CCard>
    </div>

  );

  if (error) return (
    <div className={'p-2 m-2 border text-center'}>
      <CCard>
        Error: {error}
      </CCard>
      <CRow>
        <CCol>
          <p><a href="/#/">Go back to continue using this site.</a></p>
        </CCol>
      </CRow>
    </div>
  );

  return (
    <div className={'p-2 m-2 border text-center'}>
      <CCard>
        <CRow>
          <CCol>
            <h1>Payment Successful!</h1>
            <p>Thank you for your payment.</p>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <p>
              <strong>Payment Details:</strong>
            </p>
            <ul className={'list-unstyled'}>
              <li><strong>Session ID:</strong> {sessionData.id}</li>
              <li><strong>Amount:</strong> ${(sessionData.amount_total / 100).toFixed(2)}</li>
              <li><strong>Status:</strong> {sessionData.payment_status}</li>
            </ul>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <p><a href="/#/">Thanks for your payment. Go back to continue using this site.</a></p>
          </CCol>
        </CRow>
      </CCard>
    </div>
  );
};

export default SuccessPage;
